import React, {useEffect} from "react";
import type {VoidFunctionComponent} from "react";
import s from "./WallboardTop.module.scss";
import {useWallboardTopLogic} from "./WallboardTop.logic";
import {ServiceBoardsList} from "../../../../modules/service-boards/ServiceBoardsList";
import {displayHasServiceBoardsEvent} from "../../../../services/log/events";
import {Logo} from "../../../../components/brand/logo/Logo";
import {ScreenStatsGroup} from "../../../../modules/screen-stats/group/ScreenStatsGroup";

export interface IWallboardTopProps {
}

export const WallboardTop: VoidFunctionComponent<IWallboardTopProps> = ({}) => {
  const {hasServiceBoards} = useWallboardTopLogic();
  useEffect(() => {
    displayHasServiceBoardsEvent(hasServiceBoards);
  }, [hasServiceBoards]);
  if (!hasServiceBoards) {
    return null;
  }
  return (
    <div className={s.container}>
      <ServiceBoardsList />
      <div className={s.companyStats + ` service-board-stats`}>
        <Logo />
        <ScreenStatsGroup />
      </div>
    </div>
  );
}

