import {useAppSelector} from "../../../../redux/hooks";
import {getScreenHasServiceBoards} from "../../../../redux/screen/screen.selectors";

export interface IWallboardTopLogic {
  hasServiceBoards: boolean;
}

export const useWallboardTopLogic = (): IWallboardTopLogic => {
  const hasServiceBoards = useAppSelector(getScreenHasServiceBoards);
  return {
    hasServiceBoards,
  };
}
