import {useAppSelectorWithParam} from "../../../../redux/hooks";
import {
  doesTeamMemberHaveAnyActivityToday,
  getCurrentActivityByTeamMemberId,
  getTeamMemberCurrentActivityIdById,
  getTeamMemberCurrentActivityTypeById,
  hasTeamMemberCurrentActivityById,
  hasTeamMemberLastActivityById
} from "../../../../redux/team-members/team-members.selectors";
import {
  getDisplayConfigChargeCodeById, getDisplayConfigForServiceTicketWorkTypeId,
  shouldDisplayPreviousByChargeCodeId
} from "../../../../redux/display-config/display-config.selectors";
import {AppState} from "../../../../redux/store";
import {colorChargeCodeBackgroundDefault} from "../../../../config/colors";
import {DisplayConfigStyle} from "../../../../services/firebase/database-types";
import {isUndefined} from "is-type-util";


export enum TeamMemberActivityTimeDisplayEnum {
  notClockedIn,
  current,
  previous
};

const getTeamMemberActivityTimeDisplayType = (store: AppState, teamMemberId: number): TeamMemberActivityTimeDisplayEnum => {
  const hasAnyActivity = doesTeamMemberHaveAnyActivityToday(store, teamMemberId);

  if (!hasAnyActivity) {
    return TeamMemberActivityTimeDisplayEnum.notClockedIn;
  }

  const hasCurrentActivity = hasTeamMemberCurrentActivityById(store, teamMemberId);

  if (!hasCurrentActivity) {
    // not currently clocked into anything
    return TeamMemberActivityTimeDisplayEnum.notClockedIn;
  }

  const hasPreviousActivity = hasTeamMemberLastActivityById(store, teamMemberId);
  if (!hasPreviousActivity) {
    // no previous activity to use
    return TeamMemberActivityTimeDisplayEnum.current;
  }

  const currentActivityType = getTeamMemberCurrentActivityTypeById(store, teamMemberId);
  if (currentActivityType !== `ChargeCode`){
    // only charge codes can request previous time
    return TeamMemberActivityTimeDisplayEnum.current;
  }

  const currentActivityId = getTeamMemberCurrentActivityIdById(store, teamMemberId);
  const shouldUsePreviousActivityTime = shouldDisplayPreviousByChargeCodeId(store, currentActivityId!);
  if (shouldUsePreviousActivityTime) {
    return TeamMemberActivityTimeDisplayEnum.previous;
  }
  return TeamMemberActivityTimeDisplayEnum.current;
}

interface IActivityDisplayDetails {
  id?: number;
  type?: string;
  title?: string;
  style?: DisplayConfigStyle;
}

const getActivityDisplayDetailsForTeamMemberId = (store: AppState, teamMemberId: number): IActivityDisplayDetails => {
  const activity = getCurrentActivityByTeamMemberId(store, teamMemberId);
  const displayDetails: IActivityDisplayDetails = {};

  if (isUndefined(activity)){
    return displayDetails;
  }

  if (activity.type === `ChargeCode`) {
    const chargeCodeConfig = getDisplayConfigChargeCodeById(store, activity.id);
    displayDetails.type = activity.type;
    displayDetails.title = chargeCodeConfig?.name ?? activity.workType?.name;
    displayDetails.style = {
      color: chargeCodeConfig?.color ?? colorChargeCodeBackgroundDefault,
      ...chargeCodeConfig?.style,
    };
  }

  if (activity.type === `ServiceTicket`) {
    displayDetails.id = activity.id;
    const serviceTicketWorkTypeConfig = getDisplayConfigForServiceTicketWorkTypeId(store, activity.workType?.id);
    displayDetails.type = serviceTicketWorkTypeConfig?.title ??
      activity.workType?.name ?? 'unknown';
    displayDetails.style = {
      color: serviceTicketWorkTypeConfig?.color,
      ...serviceTicketWorkTypeConfig?.style,
    };
    displayDetails.title = activity.company;
  }

  return displayDetails;

}
export interface ITeamMemberActivityLogic {
  displayTimeType: TeamMemberActivityTimeDisplayEnum;
  id?: number;
  style?: DisplayConfigStyle;
  title?: string;
  type?: string;
}

export const useTeamMemberActivityLogic = (teamMemberId: number): ITeamMemberActivityLogic => {
  const teamMemberActivityTimeDisplay = useAppSelectorWithParam<TeamMemberActivityTimeDisplayEnum, number>(getTeamMemberActivityTimeDisplayType, teamMemberId);
  const {title, style, type, id} = useAppSelectorWithParam(getActivityDisplayDetailsForTeamMemberId, teamMemberId);


  return {
    displayTimeType: teamMemberActivityTimeDisplay,
    id,
    title,
    style,
    type,
  };
}
