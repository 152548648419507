import {AppState} from "../store";
import {
  DisplayConfigStoreChargeCodesType,
  DisplayConfigStoreChargeCodeType, DisplayConfigStoreServiceTicketWorkTypesType,
  IDisplayConfigStore
} from "./display-config.slice";
import {
  DisplayConfigStyle,
  IDisplayConfigServiceTicketWorkTypeData,
  TeamMemberActivityType
} from "../../services/firebase/database-types";


const getDisplayConfigStore = (store: AppState): IDisplayConfigStore =>
  store.displayConfig;

const getDisplayConfigChargeCodes = (store: AppState): DisplayConfigStoreChargeCodesType | undefined =>
  getDisplayConfigStore(store).chargeCodes;
export const getDisplayConfigChargeCodeById = (store: AppState, id?: number): DisplayConfigStoreChargeCodeType | undefined =>
  getDisplayConfigChargeCodes(store)?.[id ?? -1];

export const shouldDisplayPreviousByChargeCodeId = (store: AppState, chargeCodeId: number): boolean =>
  !!getDisplayConfigChargeCodeById(store, chargeCodeId)?.shouldDisplayPreviousActivityTime;

export const getDisplayColorByChargeCodeId = (store: AppState, chargeCodeId: number): string | undefined =>
  getDisplayConfigChargeCodeById(store, chargeCodeId)?.color;
export const getDisplayStyleByChargeCodeId = (store: AppState, chargeCodeId: number): DisplayConfigStyle =>
  getDisplayConfigChargeCodeById(store, chargeCodeId)?.style ?? {};

const getDisplayConfigServiceTicketWorkTypes = (store: AppState): DisplayConfigStoreServiceTicketWorkTypesType | undefined => {
  const parent = getDisplayConfigStore(store);
  const value= parent.serviceTicketWorkTypes;
  return value;
}

export const getDisplayConfigForServiceTicketWorkTypeId = (store: AppState, workTypeId?: number): IDisplayConfigServiceTicketWorkTypeData | undefined =>
  getDisplayConfigServiceTicketWorkTypes(store)?.[workTypeId ?? -1];


export const getDisplayColorByTypeAndId = (store: AppState, activityType?: TeamMemberActivityType, id?: number): string | undefined => {
  switch (activityType) {
    case `ChargeCode`:
      return getDisplayColorByChargeCodeId(store, id ?? 0);
    case "ServiceTicket":
      // TODO: setup service ticket to worktype color
      return undefined;
  }
}
