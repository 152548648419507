import {useAppSelectorWithParam} from "../../redux/hooks";
import {
  getServiceBoardCriticalTicketsById,
  getServiceBoardHighTicketsById,
  getServiceBoardLowTicketsById, getServiceBoardMediumTicketsById,
  getServiceBoardNameById,
  getTotalTicketStatsByServiceBoardId, getTotalUnAssignedTicketsByServiceBoardId
} from "../../redux/service-boards/service-boards.selectors";

export interface IServiceBoardLogic {
  name: string;
  totalTicketCount: number;
  availableTicketCount: number;
  criticalAvailableCount: number;
  highAvailableCount: number;
  mediumAvailableCount: number;
  lowAvailableCount: number;
}

export const useServiceBoardLogic = (serviceBoardId: number): IServiceBoardLogic => {
  const name = useAppSelectorWithParam(getServiceBoardNameById, serviceBoardId);
  const totalTicketCount = useAppSelectorWithParam(getTotalTicketStatsByServiceBoardId, serviceBoardId) ?? 0;
  const availableTicketCount = useAppSelectorWithParam(getTotalUnAssignedTicketsByServiceBoardId, serviceBoardId) ?? 0;
  const criticalAvailableCount = useAppSelectorWithParam(getServiceBoardCriticalTicketsById, serviceBoardId) ?? 0;
  const highAvailableCount = useAppSelectorWithParam(getServiceBoardHighTicketsById, serviceBoardId) ?? 0;
  const mediumAvailableCount = useAppSelectorWithParam(getServiceBoardMediumTicketsById, serviceBoardId) ?? 0;
  const lowAvailableCount = useAppSelectorWithParam(getServiceBoardLowTicketsById, serviceBoardId) ?? 0;
  return {
    name,
    totalTicketCount,
    availableTicketCount,
    criticalAvailableCount,
    highAvailableCount,
    mediumAvailableCount,
    lowAvailableCount,
  };
}
