import React from "react";
import type {FunctionComponent} from "react";
import s from "./Logo.module.scss";
import logoImage from "./assets/911it-logo.png";

export interface ILogoProps {
}

export const Logo: FunctionComponent<ILogoProps> = ({}) => {
  return (
    <div className={s.container}>
      <img src={logoImage} alt={`911IT Logo`} className={s.logo}/>
    </div>
  );
}

