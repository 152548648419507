import {logEvent, setUserProperties} from "../../clients/firebase/analytics";
import LogRocket from "logrocket";

class ProductMonitoringService {

  constructor() {
    LogRocket.init('3yqqev/wallboard');
  }

  public identify(key: string, value: unknown): void
  {
    const identifyProps: Record<string, any> = {};
    identifyProps[key] = value;
    LogRocket.identify(identifyProps);
    setUserProperties(key, value);
  }

  public identifyScreen(uuid: string): void
  {
    LogRocket.identify(uuid);
    productMonitoring.identify(`screen_uuid`, uuid);
    productMonitoring.logEvent(`screen_identified`, {uuid});
  }

  public logEvent(eventName: string, eventProperties?: Record<string, string | number | boolean | string[] | number[] | boolean[] | undefined>): void
  {
    logEvent(eventName, eventProperties);
    LogRocket.track(eventName, eventProperties)
  }
  public logException(exception: Error, extraData?: {[key: string]: string | number | boolean}): void
  {
    LogRocket.captureException(exception, {extra: extraData});
    logEvent(`exception`, {
      exception,
      details: extraData,
    });

  }
}

export const productMonitoring = new ProductMonitoringService();
